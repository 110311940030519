import { gsap } from 'gsap';

export default class PopupReservation {
    constructor() {
        this.init();
    }

    init() {
        const popup = document.createElement('div')
        popup.classList.add('popup')
        popup.classList.add('popup-reservation')
        popup.innerHTML = `
            <div class="background button-close-popup"></div>
            <div class="popup-wrap">
                <div class="popup-container">
                    <div class="popup-header">
                        ${document.documentElement.lang === 'en' ? `<span class="popup-title">Book Tickets</span>` : `<span class="popup-title">티켓 예매하기</span>`}
                        <button class="button-close button-close-popup" data-value="once"></button>
                    </div>
                    
                    <div class="popup-content">
                    ${document.documentElement.lang === 'en' ? 
                        `<div class="button-list-container">
                            <button data-href="https://booking.naver.com/booking/5/bizes/1164406/items/5946065?tab=details" class="button-list list-naver">
                                <span class="logo"></span>
                                <span class="txt">Book on Naver</span>
                            </button>
                            <button data-href="https://booking.kakao.com/short/DAYJnZLVSG" class="button-list list-kakao">
                                <span class="logo"></span>
                                <span class="txt">Book on Kakao</span>
                            </button>
                            <button data-href="https://tickets.interpark.com/goods/24008435" class="button-list list-interpark">
                                <span class="logo"></span>
                                <span class="txt">Book on Interpark ticket</span>
                            </button>
                        </div>`
                        :
                        `<div class="button-list-container">
                            <button data-href="https://www.leisureplanner.net/lr2/?t=pv&idx=b1f62fa99de9f27a048344d55c5ef7a6#additionalInfo" class="button-list list-lightroom">
                                <span class="logo"></span>
                                <span class="txt">라이트룸으로 예매하기</span>
                            </button>
                            <button data-href="https://booking.naver.com/booking/5/bizes/1164406/items/5946065?tab=details" class="button-list list-naver">
                                <span class="logo"></span>
                                <span class="txt">네이버로 예매하기</span>
                            </button>
                            <button data-href="https://booking.kakao.com/short/DAYJnZLVSG" class="button-list list-kakao">
                                <span class="logo"></span>
                                <span class="txt">카카오로 예매하기</span>
                            </button>
                            <button data-href="https://tickets.interpark.com/goods/24008435" class="button-list list-interpark">
                                <span class="logo"></span>
                                <span class="txt">인터파크티켓으로 예매하기</span>
                            </button>
                        </div>`
                    }
                    </div>
                </div>
            </div>
        `

        document.body.appendChild(popup)

        this.popup = popup;
        this.popupContent = this.popup.querySelector('.popup-wrap');
        this.background = this.popup.querySelectorAll('.background');
        this.buttonEvent()
    }

    buttonEvent() {
        gsap.utils.toArray('.button-reservation').forEach((button) => {
            button.addEventListener('click', () => {
                this.appear()
            });
        });
        gsap.utils.toArray('.button-close-popup', this.popup).forEach((button) => {
            button.addEventListener('click', () => {
                this.disappear();
            });
        });

        gsap.utils.toArray('.button-list', this.popup).forEach(button => {
            button.addEventListener('click', () => {
                this.disappear()
                window.open(button.getAttribute('data-href'))
            })
        })
    }

    appear() {
        document.body.classList.add('is-lock');
        gsap.set(this.popup, { display: 'flex' });
        gsap.to(this.background, {
            opacity: 1,
            duration: 0.3,
            ease: 'power1.out',
            delay: 0,
        });
        gsap.to(this.popupContent, {
            opacity: 1,
            duration: 0.3,
            ease: 'power1.inOut',
            delay: 0.1,
        });
    }

    disappear() {
        gsap.to(this.background, {
            opacity: 0,
            duration: 0.4,
            ease: 'power1.inOut',
            delay: 0,
            onComplete: () => {
                gsap.set(this.popup, { display: 'none' });
                document.body.classList.remove('is-lock');
           
            },
        });
        gsap.to(this.popupContent, {
            opacity: 0,
            duration: 0.4,
            ease: 'power1.inOut',
            delay: 0
        });
    }
}