import { gsap } from 'gsap';

export default class PopupExpire {
    constructor(popup, id) {
        this.popup = popup;
        this.popupContent = this.popup.querySelector('.popup-container');
        this.background = document.querySelector('.popup-group .background');

        this.inputs = gsap.utils.toArray('input[type=checkbox]', this.popup);
        this.id = id ? '-sales' : '';

        this.storgeStatusName = `lr-popup${this.id}`
        this.storgeStatusExpire = `lr-popupExpiredTime${this.id}`
        this.init();

        this.buttonEvent();
    }

    init() {
        let popupStatus = localStorage.getItem(this.storgeStatusName);
        let popupExpire = localStorage.getItem(this.storgeStatusExpire)
        let now = new Date().getTime();
        let expireDate = new Date(parseInt(popupExpire, 10));
        console.log('팝업 만료 시간: ' + expireDate.toLocaleString(), now, popupExpire, popupStatus);

        if (popupExpire) {
            if (now > popupExpire) {
                localStorage.removeItem(`lr-popup${this.id}`);
                localStorage.removeItem(`lr-popupExpiredTime${this.id}`);
                popupStatus = null;
            }
        }

        if (popupStatus !== 'hide') {
            this.appear();
        }
    }

    buttonEvent() {
        gsap.utils.toArray('.button-close-popup', this.popup).forEach((button) => {
            button.addEventListener('click', () => {
                this.closePopup(button.getAttribute('data-value'));
                this.disappear();
            });
        });
    }

    closePopup(value) {
        if (value !== 'once') {
            localStorage.setItem(this.storgeStatusName, 'hide');
        }
        let today = new Date();
        let expiredDate = new Date(today);
        if (value === 'day') {
            expiredDate.setDate(today.getDate() + 1);
            localStorage.setItem(this.storgeStatusExpire, expiredDate.getTime());
        } else if (value === 'minute') {
            // test
            // let minute = new Date(expiredDate.getTime() + 1 * 60 * 1000); // 1분 후
            // localStorage.setItem('lr-popupExpiredTime', minute.getTime());
        } else if (value === 'never') {
            localStorage.removeItem(this.storgeStatusExpire)
        }
    }

    appear() {
        document.body.classList.add('is-lock');
        document.body.classList.add('is-appear-popup');

        this.popup.classList.add('is-active-popup');
        gsap.set(this.popup, { display: 'flex' });

        gsap.to(this.background, {
            opacity: 1,
            duration: 0.3,
            ease: 'power1.out',
            delay: 0 
        });
        gsap.to(this.popupContent, {
            opacity: 1,
            duration: 0.3,
            ease: 'power1.inOut',
            delay: 0.1,
        });
    }

    disappear() {
        this.popup.classList.remove('is-active-popup');
        if (!document.querySelector('.is-active-popup')) {
            gsap.to(this.background, {
                opacity: 0,
                duration: 0.4,
                ease: 'power1.inOut',
                delay: 0,
                onComplete: () => {
                    document.body.classList.remove('is-lock');
                    document.body.classList.remove('is-appear-popup')
                },
            });
        }

        gsap.to(this.popupContent, {
            opacity: 0,
            duration: 0.4,
            ease: 'power1.inOut',
            delay: 0,
            onComplete: () => {
                gsap.set(this.popup, { display: 'none' });
            }

        });
    }
}